<template>
    <div class="pop-wrapper" ref="customDiv">
        <div class="menu" @click="toggle">
            <img src="../assets/menu.png" alt="Menu" />
        </div>

        <div class="popup" v-if="showPopup" @click.stop>
            <RouterLink to="/reports">{{ $t('links.reports') }}</RouterLink>
            <hr />
            <RouterLink to="/upload">{{ $t('links.upload') }}</RouterLink>
            <hr />
            <RouterLink to="/">{{ $t('links.logout') }}</RouterLink>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { RouterLink } from 'vue-router'

const showPopup = ref(false)
const customDiv = ref(null)

const toggle = () => {
    showPopup.value = !showPopup.value
}

const handleClickOutside = (event) => {
    if (customDiv.value && !customDiv.value.contains(event.target)) {
        showPopup.value = false
    }
}

onMounted(() => {
    document.addEventListener("click", handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener("click", handleClickOutside)
})
</script>

<style scoped>
.pop-wrapper {
    position: absolute;
    display: flex;
}

.pop-wrapper .menu {
    cursor: pointer;
    padding: 15px;
}

.pop-wrapper .popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    margin-top: 15px;
    background-color: #fff;
    border: 1px solid #acacac;
    border-radius: 15px;
}

.pop-wrapper .popup a {
    color: #acacac;
    text-decoration: none;
}

.pop-wrapper .popup a.router-link-active {
    color: #FFB749;
}

.pop-wrapper .popup hr {
    width: 70%;
    margin: 0;
    border: 0;
    border-top: 1px solid #acacac;
}
</style>