<template>
    <HeaderComponent :title="$t('reports.title')" :hasSubtitle="false" :searchDisabled="!hasData"
        @search="handleSearch" isPrivate />

    <ListComponent ref="listRef" :list="filteredReports">
        <template #content="{ item }">
            <div class="card">
                <div class="info">
                    <span class="delete" @click="() => openModal(item.id)">x</span>
                    <div class="row">
                        <p>{{ $t("reports.card.gender") }}: <span>{{ item.gender === "M" ? $t("gender.male") :
        $t("gender.female") }}</span></p>
                        <p>{{ $t("reports.card.age") }}: <span>{{ item.age }}</span></p>
                    </div>
                    <div class="row">
                        <p>{{ $t("reports.card.location") }}: <span>{{ item.location }}</span></p>
                    </div>
                    <div class="row">
                        <p>{{ $t("reports.card.currentLocation") }}: <span>{{ item.currentLocation }}</span></p>
                    </div>
                    <div class="row full">
                        <p>{{ $t("reports.card.updatedAt") }}: <span>{{ handleDate(item.updatedAt.toDate()) }}</span>
                        </p>
                    </div>
                    <div class="row full">
                        <p>{{ $t("reports.card.moreInfo") }}: <span>{{ item.moreInfo }}</span></p>
                    </div>
                    <div class="row full">
                        <p>{{ $t("reports.card.status") }}: <span>{{ item.status === "found" ? $t("card.found") : item.status === "adopted" ? $t("card.adopted") : $t("card.lost") }}</span></p>
                    </div>
                    <div class="row full mt-auto">
                        <button class="statusChange" @click.prevent="() => openStatusModal(item.id, item.status)">
                            {{ $t("reports.card.statusChange") }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </ListComponent>

    <ModalComponent :isOpen="isModalOpen" @modal-close="closeModal" name="delete">
        <template #content>
            <VSpinnerOval v-if="isDeleting" size="50" />
            <div v-else class="delete-modal">
                <p>{{ $t("reports.modal.text") }}</p>
                <div class="buttons">
                    <button class="cancel" @click="closeModal">{{ $t("reports.modal.cancel") }}</button>
                    <button class="delete" @click="handleDelete">{{ $t("reports.modal.delete") }}</button>
                </div>
            </div>
        </template>
    </ModalComponent>

    <ModalComponent :isOpen="isStatusModalOpen" @modal-close="closeStatusModal" name="delete">
        <template #content>
            <VSpinnerOval v-if="isUpdatingStatus" size="50" />
            <div v-else class="delete-modal update-modal">
                <p>{{ $t("reports.card.statusChange") }}</p>
                <div class="radios">
                    <div>
                        <input type="radio" id="lost" value="lost" v-model="statusUpdate" />
                        <label for="male">{{ $t('card.lost') }}</label>
                    </div>
                    <div>
                        <input type="radio" id="found" value="found" v-model="statusUpdate" />
                        <label for="female">{{ $t('card.found') }}</label>
                    </div>
                    <div>
                        <input type="radio" id="adopted" value="adopted" v-model="statusUpdate" />
                        <label for="adopted">{{ $t('card.adopted') }}</label>
                    </div>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="closeStatusModal">{{ $t("reports.modal.cancel") }}</button>
                    <button class="save" :disabled="!didStatusChange" @click="handleStatusUpdate">{{ $t("reports.modal.save") }}</button>
                </div>
            </div>
        </template>
    </ModalComponent>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useCollection } from 'vuefire'
import { collection, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { getStorage, ref as storageRef, deleteObject, listAll } from "firebase/storage"
import { useDateFormat } from '@vueuse/core'
import { VSpinnerOval } from "vue3-spinners"
import { useI18n } from 'vue-i18n'
import { db } from '../firebase'
import HeaderComponent from '../components/HeaderComponent.vue'
import ListComponent from '../components/ListComponent.vue'
import ModalComponent from '../components/ModalComponent.vue'

const { t } = useI18n()

// Load data from Firestore
const reports = useCollection(collection(db, 'reports'), { wait: true })
const storage = getStorage()
const filteredReports = ref([])
const listRef = ref(null)

const hasData = computed(() => {
    return reports.value && reports.value.length > 0
})

watch(reports, () => {
    filteredReports.value = reports.value.sort((a, b) => b.updatedAt - a.updatedAt)
    listRef.value.itemsLoaded()
})

function handleSearch(val) {
    if (!val) {
        filteredReports.value = reports.value
        return
    }
    let regex = new RegExp(val, 'i')
    filteredReports.value = reports.value.filter(r => regex.test(t(r.status)) || regex.test(r.age) || regex.test(r.location) || regex.test(r.currentLocation))
    listRef.value.changePage(1)
}

function handleDate(date) {
    return useDateFormat(date, "DD/MM/YYYY HH:mm").value
}

// Delete Modal
const isDeleting = ref(false)
const isModalOpen = ref(false)
const idSelected = ref(null)

const openModal = (id) => {
    idSelected.value = id
    isModalOpen.value = true
}

const closeModal = () => {
    isModalOpen.value = false
    idSelected.value = null
    isDeleting.value = false
}

const closeModalAfterDelete = () => {
    reports.value = reports.value.filter(r => r.id !== idSelected.value)
    
    isModalOpen.value = false
    idSelected.value = null
    isDeleting.value = false
}

async function handleDelete() {
    if (!idSelected.value) {
        return
    }

    isDeleting.value = true
    // Delete the document
    await deleteDoc(doc(db, "reports", idSelected.value))

    // Delete the images
    const imagesRef = storageRef(storage, `images/${idSelected.value}`)
    listAll(imagesRef).then((res) => {
        res.items.forEach((item) => {
            deleteObject(item)
        })
    })

    closeModalAfterDelete()
}

// Update status modal
const isUpdatingStatus = ref(false)
const isStatusModalOpen = ref(false)
const idStatusSelected = ref(null)
const previousStatus = ref(null)
const statusUpdate = ref(null)
const didStatusChange = ref(false)

watch(statusUpdate, (nv, ov) => {
    didStatusChange.value = (nv !== previousStatus.value && ov !== null)
})

const openStatusModal = (id, s) => {
    idStatusSelected.value = id
    previousStatus.value = s
    statusUpdate.value = s
    isStatusModalOpen.value = true
}

const closeStatusModal = () => {
    isStatusModalOpen.value = false
    idStatusSelected.value = null
    previousStatus.value = null
    statusUpdate.value = null
    isUpdatingStatus.value = false
}

async function handleStatusUpdate() {
    if (!idStatusSelected.value) {
        return
    }

    isUpdatingStatus.value = true
    // Update the document
    await updateDoc(doc(db, "reports", idStatusSelected.value), {
        status: statusUpdate.value
    })

    closeStatusModal()
}
</script>

<style scoped>
.card {
    width: -webkit-fill-available;
    max-width: 420px;
    min-height: 200px;
    margin: 10px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.card .info {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 10px 20px 20px;
    gap: 7px;
}

.card .info .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.card .info .row.full {
    grid-template-columns: 1fr;
}

.card .info .row p {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.card .info .row p span {
    font-weight: 400;
}

.card .info .delete {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 25px;
    color: #c40f0f;
    cursor: pointer;
}

.card .statusChange {
    width: fit-content;
    justify-self: center;
    padding: 10px 15px;
    background-color: #FFB749;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid white;
    border-radius: 10px;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
    width: 250px;
}

.delete-modal p {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-align: center;
}

.delete-modal .buttons {
    display: flex;
    gap: 10px;
}

.delete-modal .buttons button {
    padding: 10px 20px;
    border: 1px solid #ACACAC;
    border-radius: 10px;
    cursor: pointer;
}

.delete-modal .buttons button.delete {
    background-color: #c40f0f;
    color: #fff;
}

.delete-modal .buttons button.save {
    background-color: #4CAF50;
    color: #fff;
}
.delete-modal .buttons button.save:disabled {
    background-color: #ccc;
    color: #fff;
}

.update-modal p {
    margin: 0;
}
</style>