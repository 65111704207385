<template>
    <div class="upload">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Drop files to upload</h3>
        </div>
        <div class="avatar-upload">
            <div class="avatar-container">
                <label for="avatar">
                    <img src="../assets/camera.png" class="rounded-circle" />
                </label>
            </div>
            <div class="text-container">
                <file-upload extensions="gif,jpg,jpeg,png,webp" accept="image/png,image/gif,image/jpeg,image/webp"
                    name="avatar" class="btn btn-primary" v-model="files" multiple
                    @input-filter="inputFilter" ref="upload">
                    <span>{{ $t('upload.select', files.length) }}</span>
                </file-upload>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineExpose, ref } from 'vue'
import FileUpload from 'vue-upload-component'

const files = ref([])
const upload = ref(null)

function alert(message) {
    window.alert(message)
}

function inputFilter(newFile, oldFile, prevent) {
    if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
            alert('Your choice is not a picture')
            return prevent()
        }
    }

    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file)
        }
    }
}

defineExpose({
    files
})
</script>

<style scoped>
.upload {
    width: 220px;
}

.upload .avatar-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 3px solid #D3D3D3;
    border-radius: 40px;
    color: #BDBDBD;
}

.upload .avatar-upload .rounded-circle {
    width: 148px;
    height: 116px;
}

.upload .avatar-edit-image {
    max-width: 100%
}

.upload .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: .6;
    text-align: center;
    background: #000;
}

.upload .drop-active h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
}
</style>