<template>
	<div class="home">
		<HeaderComponent :searchDisabled="!hasData" @search="handleSearch" />
		<ListComponent ref="listRef" :list="filteredReports">
			<template #content="{ item }">
				<CardComponent :id="item.id" :gender="item.gender" :status="item.status" :age="item.age" :imgSrc="item.photo"
								:location="item.location" :currentLocation="item.currentLocation" :moreInfo="item.moreInfo"
								@openMorePhotos="handleOpenModal" />
			</template>
		</ListComponent>
		<FooterComponent />
	</div>

	<ModalComponent :isOpen="isModalOpen" @modal-close="closeModal" name="more-photos">
		<template #content>
			<VSpinnerOval v-if="reportPhotos.length === 0" size="50" />
			<div v-else class="photos">
				<Carousel>
					<Slide v-for="(slide, ix) in reportPhotos" :key="ix">
						<div class="carousel__item">
							<img :class="`slide-${ix}`" :src="slide.url" :alt="`Slide ${ix}`" />
						</div>
					</Slide>

					<template #addons>
						<Navigation />
					</template>
				</Carousel>
			</div>
		</template>
	</ModalComponent>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useCollection } from 'vuefire'
import { collection } from 'firebase/firestore'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { VSpinnerOval } from "vue3-spinners";
import { useI18n } from 'vue-i18n'
import { db } from '../firebase'
import CardComponent from '../components/CardComponent.vue'
import ModalComponent from '../components/ModalComponent.vue'
import HeaderComponent from '../components/HeaderComponent.vue'
import ListComponent from '../components/ListComponent.vue'
import FooterComponent from '../components/FooterComponent.vue';

const { t } = useI18n()

// Load data from Firestore
const reports = useCollection(collection(db, 'reports'), { once: true, wait: true })
const filteredReports = ref([])
const listRef = ref(null)

const hasData = computed(() => {
	return reports.value && reports.value.length > 0
})

watch(reports, () => {
	filteredReports.value = reports.value.sort((a, b) => b.updatedAt - a.updatedAt)
	listRef.value.itemsLoaded()
})

function handleSearch(val) {
	if (!val) {
		filteredReports.value = reports.value
		return
	}
    let regex = new RegExp(val, 'i')
	filteredReports.value = reports.value.filter(r => regex.test(t(r.status)) || regex.test(r.age) || regex.test(r.location) || regex.test(r.currentLocation))
	listRef.value.changePage(1)
}

// Modal
const isModalOpen = ref(false);
const idSelected = ref(null);

const openModal = () => {
    isModalOpen.value = true;
}

const closeModal = () => {
    isModalOpen.value = false;
    idSelected.value = null;
}

function handleOpenModal(id) {
    openModal();
    idSelected.value = id;
}

// Load more photos
const reportPhotos = computed(() => {
    if (!idSelected.value) return []
    return useCollection(collection(db, "reports", idSelected.value, "photos"), { once: true }).value.sort((a, b) => b.createdAt - a.createdAt)
})
</script>

<style scoped>
.carousel__item {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.list {
    min-height: 50vh;
    justify-content: center;
}
</style>
