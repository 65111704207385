<template>
    <div class="header">
        <template v-if="isPrivate">
            <NavigationPopup />
        </template>
        <div class="imgs">
            <img alt="Lens" src="../assets/lens.png">
            <img alt="Puppy" src="../assets/puppy.png">
        </div>
        <div class="titles">
            <h1>{{ title ?? $t("header.title") }}</h1>
            <h2 v-if="hasSubtitle">{{ subtitle ?? $t("header.subtitle") }}</h2>
        </div>
        <div v-if="hasSearch" class="search">
            <div class="searchbox">
                <img alt="search" src="../assets/search.png">
                <input ref="searchRef" type="text" :placeholder="$t('header.search')" v-model="search" :disabled="searchDisabled">
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, ref, watch } from "vue";
import debounce from "lodash.debounce"
import NavigationPopup from "./NavigationPopup.vue";

defineProps({
    title: {
        type: String
    },
    subtitle: {
        type: String
    },
    hasSubtitle: {
        type: Boolean,
        default: true
    },
    hasSearch: {
        type: Boolean,
        default: true
    },
    searchDisabled: {
        type: Boolean,
        default: false
    },
    isPrivate: {
        type: Boolean,
        default: false
    }
})

const searchRef = ref(null)
const search = ref("")
const emit = defineEmits(["search"])

watch(search, debounce(() => {
    emit("search", search.value)
}, 500))

defineExpose({ searchRef })
</script>

<style scoped>
.header {
    background-color: #FFB749;
    color: #fff;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.header .imgs {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0 10px;
}

.header .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
}
.header .titles h1 {
    max-width: 250px;
    font-size: 20px;
    font-weight: 900;
    margin: 5px;
}
.header .titles h2 {
    max-width: 300px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px;
}

.header .search {
    display: flex;
    justify-content: center;
    padding: 0 20px 15px 20px;
}
.header .search .searchbox {
    width: 80%;
    display: flex;
    color: #ACACAC;
    gap: 12px;
    padding: 4px 8px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.header .search .searchbox input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    color: #000;
}
.header .search .searchbox input::placeholder,
.header .search .searchbox input::-webkit-input-placeholder {
    color: #ACACAC;
}
</style>