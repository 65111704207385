<template>
    <div class="list">
        <VSpinnerOval v-if="itemsLoading" size="50" />
        <template v-else>
            <template v-if="displayedItems.length > 0">
                <div class="items">
                    <template v-for="(item, ix) in displayedItems" :key="ix">
                        <slot name="content" :item="item"></slot>
                    </template>
                </div>
                <div class="pagination">
                    <button type="button" class="prev" v-if="currentPage > 1" @click="changePage(currentPage - 1)"></button>
                    <button type="button" :class="[{ isActive: page === currentPage }]" v-for="page in pages" :key="page"
                        @click="page !== '...' && changePage(page)">
                        {{ page }}
                    </button>
                    <button type="button" class="next" v-if="currentPage < pageCount"
                        @click="changePage(currentPage + 1)"></button>
                </div>
            </template>
            <p v-else class="not-found">{{ $t('list.notFound') }}</p>
        </template>
    </div>
</template>

<script setup>
import { computed, defineExpose, defineProps, nextTick, ref, watch } from 'vue'
import { VSpinnerOval } from "vue3-spinners";

import 'vue3-carousel/dist/carousel.css'

const props = defineProps({
    list: {
        type: Array,
        required: true
    },
    itemsPerPage: {
        type: Number,
        default: 20
    }
})

const itemsLoading = ref(true)

// Pagination
const currentPage = ref(1);
const pageCount = computed(() => {
    return Math.ceil(props.list.length / props.itemsPerPage)
})
const pages = computed(() => {
    const totalPageCount = pageCount.value
    const maxPagesToShow = 5
    let startPage = Math.max(currentPage.value - 2, 1)
    let endPage = startPage + maxPagesToShow - 1

    if (endPage > totalPageCount) {
        endPage = totalPageCount
        startPage = Math.max(endPage - maxPagesToShow + 1, 1)
    }

    const pageArray = []
    if (startPage > 1) {
        pageArray.push(1)
        if (startPage > 2) {
            pageArray.push('...')
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageArray.push(i)
    }

    if (endPage < totalPageCount) {
        if (endPage < totalPageCount - 1) {
            pageArray.push('...')
        }
        pageArray.push(totalPageCount)
    }

    return pageArray
})
const displayedItems = computed(() => {
    const start = (currentPage.value - 1) * props.itemsPerPage
    const end = start + props.itemsPerPage
    return props.list.slice(start, end)
})

function changePage(pageNumber) {
    currentPage.value = pageNumber
}

watch(currentPage, () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
})

function itemsLoaded() {
    nextTick(() => {
        itemsLoading.value = false
    })
}

// Expose functions
defineExpose({
    changePage,
    itemsLoaded
})
</script>

<style scoped>
.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.list .v-spinner {
    margin-top: 30px;
}

.list .not-found {
    font-size: 20px;
    font-weight: 700;
}

.list .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.list .pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    gap: 10px;
}

.list .pagination button {
    font-size: 20px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: #B1B1B1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.list .pagination button.isActive {
    font-size: 32px;
    color: #FFB749;
    pointer-events: none;
}

.list .pagination button.next,
.list .pagination button.prev {
    display: flex;
    align-self: center;
    mask-image: url(../assets/arrow-down.png);
    background-color: #FFB749;
    width: 18px;
    height: 18px;
}

.list .pagination button.next {
    transform: rotate(270deg);
}

.list .pagination button.prev {
    transform: rotate(90deg);
}

@media screen and (min-width: 768px) {
    .list .items {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
}
</style>