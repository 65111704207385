<template>
    <RouterView />
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
}

#app {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

#app .carousel__slide {
    padding: 10px;
}

#app .carousel__prev,
#app .carousel__next {
    color: #FFB749;
    transform: scale(1.5);
    margin: 0;
}

#app .carousel__prev {
    left: -15px;
}

#app .carousel__next {
    right: -15px;
}

#app .v-spinner {
    color: #FFB749;
}

#app .modal-transparent {
    background-color: transparent;
    box-shadow: none;
}

#app .mt-auto {
    margin-top: auto;
}
</style>