<template>
    <HeaderComponent :title="$t('upload.title')" :subtitle="$t('upload.subtitle')" :hasSearch="false" isPrivate />

    <div class="select-photo">
        <AvatarUpload ref="filesRef" />

        <div class="radios">
            <div>
                <input type="radio" id="male" value="M" v-model="gender" />
                <label for="male">{{ $t('gender.male') }}</label>
            </div>
            <div>
                <input type="radio" id="female" value="F" v-model="gender" />
                <label for="female">{{ $t('gender.female') }}</label>
            </div>
        </div>

        <div class="input-text required">
            <label for="location">{{ $t("upload.location") }}</label>
            <input type="text" name="location" v-model="location" />
        </div>
        <div class="input-text required">
            <label for="currentLocation">{{ $t("upload.currentLocation") }}</label>
            <input type="text" name="currentLocation" v-model="currentLocation" />
        </div>
        <div class="input-text">
            <label for="age">{{ $t("upload.age") }}</label>
            <input type="text" name="age" v-model="age" />
        </div>
        <div class="input-text">
            <label for="more-info">{{ $t("upload.moreInfo") }}</label>
            <input type="text" name="more-info" v-model="moreInfo" maxlength="40" />
        </div>

        <button type="button" class="submit" @click="handleSubmit" :disabled="buttonDisabled">
            {{ $t("upload.submit") }}
        </button>
    </div>

    <ModalComponent :isOpen="isModalOpen" @modal-close="closeModal" name="success" className="modal-transparent">
        <template #content>
            <div v-if="!hasError">
                <img src="../assets/check.png" alt="Success" width="200px" height="200px" />
                <p class="success-text">{{ $t("upload.success") }}</p>
            </div>
            <div v-else>
                <img src="../assets/error.png" alt="Error" width="200px" height="200px" style="margin: 20px;" />
                <p class="success-text">{{ $t("upload.error") }}</p>
            </div>
        </template>
    </ModalComponent>

    <ModalComponent :isOpen="isAdding" name="loading">
        <template #content>
            <VSpinnerOval size="50" />
        </template>
    </ModalComponent>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useFirebaseStorage } from 'vuefire'
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage'
import { addDoc, collection, updateDoc } from 'firebase/firestore'
import { VSpinnerOval } from "vue3-spinners"
import HeaderComponent from '../components/HeaderComponent.vue'
import AvatarUpload from '../components/AvatarUpload.vue'
import ModalComponent from '../components/ModalComponent.vue'
import { db } from '../firebase'

const storage = useFirebaseStorage()
const reportsCollection = collection(db, 'reports')

const isAdding = ref(false)
const hasError = ref(false)

const filesRef = ref(null)
const gender = ref("M")
const age = ref("")
const location = ref("")
const currentLocation = ref("")
const moreInfo = ref("")

const buttonDisabled = computed(() => {
    return !currentLocation.value || !location.value
})

async function handleSubmit() {
    if (filesRef.value.files.length === 0 || !currentLocation.value || !location.value) {
        return
    }

    isAdding.value = true
    try {
        const doc = await addDoc(reportsCollection, {
            gender: gender.value,
            age: age.value,
            location: location.value,
            currentLocation: currentLocation.value,
            moreInfo: moreInfo.value,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: 'lost'
        })

        filesRef.value.files.forEach(async (file, ix) => {
            const imagesRef = storageRef(storage, `images/${doc.id}/${file.name}`)
            const uploadedFile = await uploadBytes(imagesRef, file.file)

            const downloadUrl = await getDownloadURL(uploadedFile.ref)
            // Save the URL to the Firestore
            if (ix === 0) {
                await updateDoc(doc, {
                    photo: downloadUrl,
                    updatedAt: new Date()
                })
            }

            const docCollection = collection(db, 'reports', doc.id, 'photos')
            await addDoc(docCollection, {
                url: downloadUrl,
                createdAt: new Date()
            })
        })
    } catch (error) {
        console.error(error)
        hasError.value = true
    }

    openModal()
}

function clearForm() {
    filesRef.value.files = []
    gender.value = "M"
    age.value = ""
    location.value = ""
    currentLocation.value = ""
    moreInfo.value = ""
    hasError.value = false
}

// Modal
const isModalOpen = ref(false);

const openModal = () => {
    isAdding.value = false
    isModalOpen.value = true;
    if (!hasError.value) {
        clearForm()
    }
}

const closeModal = () => {
    isModalOpen.value = false;
    hasError.value = false;
}
</script>

<style scoped>
.select-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px 0;
}

.select-photo .radios {
    display: flex;
    gap: 10px;
}

.select-photo .radios div {
    display: flex;
    align-items: center;
}

.select-photo .radios label {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.select-photo .radios [type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #BDBDBD;
    border-radius: 50%;
    margin-right: 10px;
    background-color: transparent;
    position: relative;
}

.select-photo .radios input[type="radio"]:checked::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #FFB749;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.select-photo .input-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
    max-width: 300px;
}

.select-photo .input-text label {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding-left: 20px;
}

.select-photo .input-text.required label::after {
    content: "*";
    color: #FFB749;
    padding-left: 3px;
}

.select-photo input[type="text"] {
    height: 35px;
    background-color: #F7F7F7;
    border: 1.5px solid #DCD9EE;
    border-radius: 20px;
    padding: 2px 20px;
}

.select-photo input[type="text"]:focus-visible {
    outline: none;
    border-color: #FFB749;
}

.select-photo button.submit {
    margin-top: 10px;
    width: 70%;
    max-width: 300px;
    padding: 10px 20px;
    border: 1.5px solid #BDBDBD;
    border-radius: 10px;
    background-color: #F7F7F7;
    color: #000;
    font-size: 16px;
}

.select-photo button.submit:disabled {
    background-color: #BDBDBD;
    color: #F7F7F7
}

.success-text {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-top: 0;
    text-transform: uppercase;
}
</style>