<template>
    <div v-if="isOpen" class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" :class="className" ref="target">
                <div class="modal-content">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue"
import { onClickOutside } from "@vueuse/core"

defineProps({
    isOpen: Boolean,
    className: {
        type: String,
        default: ""
    }
})

const emit = defineEmits(["modal-close"]);

const target = ref(null)
onClickOutside(target, () => emit('modal-close'))
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
}

.modal-container {
    position: fixed;
    max-width: 320px;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
</style>