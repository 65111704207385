<template>
    <div class="card">
        <img class="photo" :src="imgSrc" alt="photo" />
        <div class="info">
            <div class="title">
                <h1>{{ genderText }}</h1>
                <img v-if="gender === 'M'" src="../assets/male.png" alt="male logo" width="24px" height="24px" />
                <img v-if="gender === 'F'" src="../assets/female.png" alt="female logo" width="24px" height="24px" />
            </div>
            <p>{{ age }}</p>
            <p><span class="mark">{{ t("card.location") }}:</span> {{ location }}</p>
            <p><span class="mark">{{ t("card.currentLocation") }}:</span> {{ currentLocation }}</p>
            <p v-if="moreInfo"><span class="mark">{{ t("card.moreInfo") }}:</span> {{ moreInfo }}</p>
            <template v-if="status === 'lost'">
                <p v-if="imgSrc" class="more" @click="$emit('openMorePhotos', id)">{{ t("card.more") }}</p>
            </template>
            <template v-else>
                <span class="athome">{{ statusText }}</span>
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
    id: String,
    status: String,
    gender: String,
    age: String,
    location: String,
    currentLocation: String,
    moreInfo: String,
    imgSrc: String
})

const statusText = computed(() => {
    return props.status === "adopted" ? t("card.adopted") : t("card.found")
})

const genderText = computed(() => {
    return props.gender === "M" ? t("gender.male") : t("gender.female")
})
</script>

<style scoped>
.card {
    width: -webkit-fill-available;
    max-width: 420px;
    min-height: 220px;
    margin: 10px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.card img.photo {
    width: 50%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}
.card .info {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 15px 10px 10px 20px;
    gap: 5px;
}
.card .info .title {
    display: flex;
    justify-content: space-between;
}
.card .info .title h1 {
    font-size: 18px;
    font-weight: 900;
    margin: 0;
}
.card .info p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.card .info span.mark {
    color: #FFB749;
}
.card .info .more {
    align-self: center;
    margin-top: auto;
    cursor: pointer;
    color: #B1B1B1;
}
.card .info .athome {
    font-size: 16px;
    font-weight: 600;
    background-color: #4CAF50;
    width: fit-content;
    text-transform: uppercase;
    color: #fff;
    padding: 5px 15px;
    margin-top: auto;
    align-self: center;
}
</style>