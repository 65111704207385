import { createWebHistory, createRouter } from "vue-router";
import { getCurrentUser, useFirebaseAuth } from "vuefire";

import HomePage from "./views/HomePage.vue";
import LoginPage from "./views/LoginPage.vue";
import UploadPage from "./views/UploadPage.vue";
import ReportsPage from "./views/ReportsPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      logout: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/upload",
    name: "upload",
    component: UploadPage,
    meta: {
      needAuth: true,
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsPage,
    meta: {
      needAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  if (to.meta.logout) {
    const auth = useFirebaseAuth()
    auth.signOut();
  }
  if (to.meta.needAuth) {
    const currentUser = await getCurrentUser();
    if (!currentUser && to.name !== "login") {
      if (!["login", "home"].includes(to.name)) {
        return {
          name: "login",
          query: { redirect: to.name },
        };
      }

      return { name: "login" };
    }
  }

  if (to.path === "/redirect") {
    if (from.query.redirect)
      return { name: from.query.redirect };
    else
      return { name: "reports" };
  }

  if (to.path === "/redirect" && ["upload", "reports"].includes(from.name)) {
    return false;
  }

  return true;
});

export default router;
