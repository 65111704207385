import { createApp } from "vue";
import { VueFire, VueFireAuthWithDependencies } from "vuefire";
import { browserLocalPersistence } from "firebase/auth";
import { firebaseApp } from "./firebase";
import i18n from "./i18n";
import router from "./router";

import App from "./App.vue";
import "vue3-spinners/spinners.css";

const app = createApp(App);

app.use(router);
app.use(VueFire, {
    firebaseApp,
    modules: [VueFireAuthWithDependencies({
        dependencies: {
            persistence: browserLocalPersistence
        }
    })],
});
app.use(i18n);

app.mount("#app");
