<template>
    <div class="login">
        <div class="imgs">
            <img alt="Lens" src="../assets/lens.png">
            <img alt="Puppy" src="../assets/puppy.png">
        </div>
        <div class="titles">
            <h1>{{ t("login.title") }}</h1>
        </div>
        <form>
            <input class="email" type="email" v-model="email" :placeholder="t('login.email')">
            <input class="password" type="password" v-model="password" :placeholder="t('login.password')" @keypress="handleKeypress">
            <button @click.prevent="login()">{{ t("login.login") }}</button>
        </form>
        <p v-if="error" class="error">{{ error }}</p>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useFirebaseAuth } from 'vuefire'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useI18n } from 'vue-i18n'

const router = useRouter()

const { t } = useI18n()

const auth = useFirebaseAuth()
const email = ref("")
const password = ref("")

async function handleKeypress(event) {
    if (event.key === "Enter") {
        await login()
    }
}

const error = ref(null)
async function login() {
    try {
        error.value = null
        const response = await signInWithEmailAndPassword(auth, email.value, password.value)
        if (response.user) {
            router.push("/redirect")
        }
    } catch (err) {
        console.error(err)
        error.value = t("login.error")
        password.value = ""
    }
}
</script>

<style scoped>
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10%;
    height: 100vh;
    background-color: #FFB749;
    color: #fff;
}
.login .imgs {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0 10px;
}
.login .titles h1 {
    max-width: 250px;
    font-size: 16px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
}

.login form {
    text-align: center;
}
.login .email,
.login .password {
    width: 80%;
    max-width: 350px;
    height: 20px;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: #000;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.login .email::placeholder,
.login .password::placeholder {
    color: #ACACAC;
}
.login button {
    width: 50%;
    max-width: 200px;
    padding: 10px 20px;
    background-color: #fff;
    color: #ACACAC;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.login .error {
    margin-top: 20px;
    color: #c40f0f;
    font-size: 16px;
}
</style>