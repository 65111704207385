import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyCkroeNe_XfB4BxzQ7HJUfKY8gYgDE8v9s",
    authDomain: "mypets-br-bo.firebaseapp.com",
    projectId: "mypets-br-bo",
    storageBucket: "mypets-br-bo.appspot.com",
    messagingSenderId: "426355182946",
    appId: "1:426355182946:web:1fcf6f95f4fe70fc90f03b",
    measurementId: "G-Q49MW8J1SB"
})

export const analytics = getAnalytics(firebaseApp)
export const db = getFirestore(firebaseApp)