<template>
    <div class="footer">
        <div class="imgs">
            <img alt="My Pets" src="../assets/mypets.png">
            <img alt="Planeta Vivo" src="../assets/planeta-vivo.png">
        </div>
        <hr class="separator" />
        <div class="contact">
            <span>{{ $t("footer.contact") }}</span>
            <div class="email">
                <img alt="email" src="../assets/mail.png">
                <a :href="`mailto:${email}`">{{ email }}</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const email = ref("mypetsresgate@gmail.com")
</script>

<style scoped>
.footer {
    background-color: #FFB749;
    color: #fff;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.footer .imgs {
    display: flex;
    justify-content: center;
    gap: 0;
    padding-top: 20px;
}

.footer .separator {
    width: 75%;
    border: 1px solid #fff;
}

.footer .contact {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 40px;
    gap: 10px;
}

.footer .contact span {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: #fff;
    color: #FFB749;
    border-radius: 7px;
}

.footer .contact .email {
    max-width: 250px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.footer .contact .email img {
    height: 20px;
}
.footer .contact .email a {
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
}
</style>